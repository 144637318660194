export const MAX_SCORE = 75;
export const data = [
  {
    id: 0,
    domain: 'Orthostatic Intolerance',
    currentScore: 0,
    maxScore: 10,
    multiplier: 4,
    questions: [
      {
        id: 1,
        questionText: `In the past year, have you ever felt faint, dizzy, “goofy”, or had difficulty thinking soon after standing up from a sitting or lying position?`,
        value: null,
        pointsKey: [1, 0],
        dependentAnswer: 2,
      },
      {
        id: 2,
        questionText: `When standing up, how frequently do you get these feelings or symptoms?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
        dependent: 1,
      },
      {
        id: 3,
        questionText: `How would you rate the severity of these feelings or symptoms?`,
        value: null,
        pointsKey: [1, 2, 3],
        dependent: 1,
      },
      {
        id: 4,
        questionText: `In the past year, have these feelings or symptoms that you have experienced:`,
        value: null,
        pointsKey: [3, 2, 1, 0, 0, 0],
        dependent: 1,
      },
    ],
  },
  {
    id: 1,
    domain: 'Vasomotor',
    maxScore: 6,
    currentScore: 0,
    multiplier: 0.8333,
    questions: [
      {
        id: 5,
        questionText: `In the past year, have you ever noticed color changes in your skin, such as red, white, or purple?`,
        value: null,
        pointsKey: [1, 0],
        dependentAnswer: 2,
      },
      {
        id: 6,
        questionText: `What parts of your body are affected by these color changes? (Check all that apply)`,
        value: null,
        pointsKey: [1, 1, 2],
        type: 'checkmark',
        dependent: 5,
      },
      {
        id: 7,
        questionText: `Are these changes in your skin color:`,
        value: null,
        pointsKey: [3, 2, 1, 0],
        dependent: 5,
      },
    ],
  },
  {
    id: 2,
    domain: 'Secretomotor',
    maxScore: 7,
    currentScore: 0,
    multiplier: 2.1428571,
    questions: [
      {
        id: 8,
        questionText: `In the past 5 years, what changes, if any, have occurred in your general body sweating?`,
        value: null,
        pointsKey: [1, 0, 0, 1, 2, 0],
      },
      {
        id: 9,
        questionText: `Do your eyes feel excessively dry?`,
        value: null,
        pointsKey: [1, 0],
      },
      {
        id: 10,
        questionText: `Does you mouth feel excessively dry?`,
        value: null,
        pointsKey: [1, 0],
      },
      {
        id: 11,
        questionText: `For the symptom of dry eyes or dry mouth that you have had for the longest period of time, is this symptom:`,
        value: null,
        pointsKey: [0, 3, 2, 1, 0, 0, 0],
      },
    ],
  },
  {
    id: 3,
    domain: 'Gastrointenstinal',
    maxScore: 28,
    currentScore: 0,
    multiplier: 0.8928571,
    questions: [
      {
        id: 12,
        questionText: `In the past year, have you noticed any changes in how quickly you get full when eating a meal? `,
        value: null,
        pointsKey: [2, 1, 0, 0, 0],
      },
      {
        id: 13,
        questionText: `In the past year, have you felt excessively full or persistently full (bloated feeling) after a meal?`,
        value: null,
        pointsKey: [0, 1, 2],
      },
      {
        id: 14,
        questionText: `In the past year, have you vomited after a meal?`,
        value: null,
        pointsKey: [0, 1, 2],
      },
      {
        id: 15,
        questionText: `In the past year, have you had a cramping or colicky abdominal pain?`,
        value: null,
        pointsKey: [0, 1, 2],
      },
      {
        id: 16,
        questionText: `In the past year, have you had any bouts of diarrhea?`,
        value: null,
        pointsKey: [1, 0],
        dependentAnswer: 2,
      },
      {
        id: 17,
        questionText: `How frequently does this occur?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
        dependent: 16,
      },
      {
        id: 18,
        questionText: `How severe are these bouts of diarrhea?`,
        value: null,
        pointsKey: [1, 2, 3],
        dependent: 16,
      },
      {
        id: 19,
        questionText: `Are your bouts of diarrhea getting:`,
        value: null,
        pointsKey: [3, 2, 1, 0, 0, 0],
        dependent: 16,
      },
      {
        id: 20,
        questionText: `In the past year, have you been constipated?`,
        value: null,
        pointsKey: [1, 0],
      },
      {
        id: 21,
        questionText: `How frequently are you constipated?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
        dependent: 20,
      },
      {
        id: 22,
        questionText: `How severe are these episodes of constipation?`,
        value: null,
        pointsKey: [1, 2, 3],
        dependent: 20,
      },
      {
        id: 23,
        questionText: `Is your constipation getting:`,
        value: null,
        pointsKey: [3, 2, 1, 0, 0, 0],
        dependent: 20,
      },
    ],
  },
  {
    id: 4,
    domain: 'Bladder',
    maxScore: 9,
    currentScore: 0,
    multiplier: 1.111,
    questions: [
      {
        id: 24,
        questionText: `In the past year, have you ever lost control of your bladder function?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
      },
      {
        id: 25,
        questionText: `In the past year, have you had difficulty passing urine?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
      },
      {
        id: 26,
        questionText: `In the past year, have you had trouble completely emptying your bladder?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
      },
    ],
  },
  {
    id: 5,
    domain: 'Pupillomotor',
    maxScore: 15,
    currentScore: 0,
    multiplier: 0.333,
    questions: [
      {
        id: 27,
        questionText: `In the past year, without sunglasses or tinted glasses, has bright light bothered your eyes?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
        dependentAnswer: 1,
      },
      {
        id: 28,
        questionText: `How severe is this sensitivity to bright light?`,
        value: null,
        pointsKey: [1, 2, 3],
        dependent: 27,
      },
      {
        id: 29,
        questionText: `In the past year, have you had trouble focusing your eyes?`,
        value: null,
        pointsKey: [0, 1, 2, 3],
        dependentAnswer: 1,
      },
      {
        id: 30,
        questionText: `How severe is this focusing problem?`,
        value: null,
        pointsKey: [1, 2, 3],
        dependent: 29,
      },
      {
        id: 31,
        questionText: `Is the most troublesome symptom with your eyes (i.e. sensitivity to bright light or trouble focusing) getting:`,
        value: null,
        pointsKey: [0, 3, 2, 1, 0, 0, 0],
      },
    ],
  },
];